var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('label',[_vm._v("Integrations:")]),_c('el-select',{staticStyle:{"width":"100%"},on:{"change":_vm.resetFormAndUpdateEvents},model:{value:(_vm.addStatsData.integrationsSettings.integration_id),callback:function ($$v) {_vm.$set(_vm.addStatsData.integrationsSettings, "integration_id", $$v)},expression:"addStatsData.integrationsSettings.integration_id"}},_vm._l((_vm.getPredefinedApplications),function(integrate){return _c('el-option',{key:integrate._id,attrs:{"label":integrate.name,"value":integrate._id}})}),1),_c('label',{staticStyle:{"display":"block","margin-bottom":"10px"}},[_vm._v("Event:")]),_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select event","clearable":true},on:{"change":_vm.setAsset},model:{value:(_vm.addStatsData.integrationsSettings.event_id),callback:function ($$v) {_vm.$set(_vm.addStatsData.integrationsSettings, "event_id", $$v)},expression:"addStatsData.integrationsSettings.event_id"}},_vm._l((_vm.selectedApplicationEvents),function(option,index){return _c('el-option',{key:index,attrs:{"label":option.eventName.replaceAll('_', ' '),"value":option._id}})}),1),(
      _vm.addStatsData.integrationsSettings.event &&
      _vm.addStatsData.integrationsSettings.event.settings.isDateFilterIncluded &&
      _vm.addStatsData.display_type !== 'FORMULA'
    )?_c('el-checkbox',{staticStyle:{"width":"100%","margin-top":"10px"},attrs:{"disabled":""},model:{value:(_vm.addStatsData.apply_date_filter),callback:function ($$v) {_vm.$set(_vm.addStatsData, "apply_date_filter", $$v)},expression:"addStatsData.apply_date_filter"}},[_vm._v("Apply date filter")]):_vm._e(),_vm._l((_vm.addStatsData.integrationsSettings.event?.settings
      .requiredFields),function(field){return _c('div',{key:field.key},[_c('label',{staticStyle:{"display":"block","margin-bottom":"10px"}},[_vm._v(_vm._s(field.label))]),(field.type == 'select')?_c('div',[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":field.placeholder ? field.placeholder : 'select any',"clearable":true,"filterable":""},on:{"change":function($event){return _vm.loadDependentFields(field)}},model:{value:(_vm.addStatsData.integrationsSettings.dependencies[field.key]),callback:function ($$v) {_vm.$set(_vm.addStatsData.integrationsSettings.dependencies, field.key, $$v)},expression:"addStatsData.integrationsSettings.dependencies[field.key]"}},_vm._l((field.values),function(option){return _c('el-option',{key:option.id,attrs:{"label":option.name,"value":option.id}})}),1)],1):_vm._e()])}),(
      this.addStatsData.integrationsSettings.event &&
      this.addStatsData.integrationsSettings.event.settings.supportType !=
        'stat' &&
      this.addStatsData.integrationsSettings.event.settings.availableColumns
    )?_c('div',[_c('label',{staticStyle:{"display":"block","margin-bottom":"10px"}},[_vm._v("Dimension:")]),_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select Fields","clearable":true},model:{value:(_vm.addStatsData.integrationsSettings.groupBy),callback:function ($$v) {_vm.$set(_vm.addStatsData.integrationsSettings, "groupBy", $$v)},expression:"addStatsData.integrationsSettings.groupBy"}},_vm._l((this.addStatsData.integrationsSettings.event
          .settings.dimensions),function(field,index){return _c('el-option',{key:index,attrs:{"label":field.label,"value":field.key}})}),1),_c('label',{staticStyle:{"display":"block","margin-bottom":"10px"}},[_vm._v("Fields:")]),_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select Fields","clearable":true,"multiple":""},model:{value:(_vm.addStatsData.integrationsSettings.selectedFields),callback:function ($$v) {_vm.$set(_vm.addStatsData.integrationsSettings, "selectedFields", $$v)},expression:"addStatsData.integrationsSettings.selectedFields"}},_vm._l((this.addStatsData.integrationsSettings.event
          .settings.availableColumns),function(field,index){return _c('el-option',{key:index,attrs:{"label":field.label,"value":field.key}})}),1),_c('label',{staticStyle:{"display":"block","margin-bottom":"10px"}},[_vm._v("Limit:")]),_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select Limit","clearable":true,"default-first-option":""},model:{value:(_vm.addStatsData.integrationsSettings.limit),callback:function ($$v) {_vm.$set(_vm.addStatsData.integrationsSettings, "limit", $$v)},expression:"addStatsData.integrationsSettings.limit"}},_vm._l((_vm.limitOptions),function(value,index){return _c('el-option',{key:index,attrs:{"label":value,"value":value}})}),1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }