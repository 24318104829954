<template>
  <div>
    <label>Integrations:</label>
    <el-select
      v-model="addStatsData.integrationsSettings.integration_id"
      style="width: 100%"
      @change="resetFormAndUpdateEvents"
    >
      <el-option
        v-for="integrate in getPredefinedApplications"
        :key="integrate._id"
        :label="integrate.name"
        :value="integrate._id"
      ></el-option>
    </el-select>
    <label style="display: block; margin-bottom: 10px">Event:</label>
    <el-select
      v-model="addStatsData.integrationsSettings.event_id"
      placeholder="Select event"
      style="width: 100%"
      :clearable="true"
      @change="setAsset"
    >
      <el-option
        v-for="(option, index) in selectedApplicationEvents"
        :key="index"
        :label="option.eventName.replaceAll('_', ' ')"
        :value="option._id"
      />
    </el-select>
    <el-checkbox
      v-if="
        addStatsData.integrationsSettings.event &&
        addStatsData.integrationsSettings.event.settings.isDateFilterIncluded &&
        addStatsData.display_type !== 'FORMULA'
      "
      v-model="addStatsData.apply_date_filter"
      style="width: 100%; margin-top: 10px"
      disabled
      >Apply date filter</el-checkbox
    >
    <div
      v-for="field in addStatsData.integrationsSettings.event?.settings
        .requiredFields"
      :key="field.key"
    >
      <label style="display: block; margin-bottom: 10px">{{
        field.label
      }}</label>
      <div v-if="field.type == 'select'">
        <el-select
          v-model="addStatsData.integrationsSettings.dependencies[field.key]"
          :placeholder="field.placeholder ? field.placeholder : 'select any'"
          style="width: 100%"
          :clearable="true"
          filterable
          @change="loadDependentFields(field)"
        >
          <el-option
            v-for="option in field.values"
            :key="option.id"
            :label="option.name"
            :value="option.id"
          />
        </el-select>
      </div>
    </div>
    <div
      v-if="
        this.addStatsData.integrationsSettings.event &&
        this.addStatsData.integrationsSettings.event.settings.supportType !=
          'stat' &&
        this.addStatsData.integrationsSettings.event.settings.availableColumns
      "
    >
      <label style="display: block; margin-bottom: 10px">Dimension:</label>
      <el-select
        v-model="addStatsData.integrationsSettings.groupBy"
        placeholder="Select Fields"
        style="width: 100%"
        :clearable="true"
      >
        <el-option
          v-for="(field, index) in this.addStatsData.integrationsSettings.event
            .settings.dimensions"
          :key="index"
          :label="field.label"
          :value="field.key"
        />
      </el-select>
      <label style="display: block; margin-bottom: 10px">Fields:</label>
      <el-select
        v-model="addStatsData.integrationsSettings.selectedFields"
        placeholder="Select Fields"
        style="width: 100%"
        :clearable="true"
        multiple
      >
        <el-option
          v-for="(field, index) in this.addStatsData.integrationsSettings.event
            .settings.availableColumns"
          :key="index"
          :label="field.label"
          :value="field.key"
        />
      </el-select>
      <label style="display: block; margin-bottom: 10px">Limit:</label>
      <el-select
        v-model="addStatsData.integrationsSettings.limit"
        placeholder="Select Limit"
        style="width: 100%"
        :clearable="true"
        default-first-option
      >
        <el-option
          v-for="(value, index) in limitOptions"
          :key="index"
          :label="value"
          :value="value"
        />
      </el-select>
    </div>
  </div>
</template>
<script>
import { postAPICall } from "@/helpers/httpHelper";
export default {
  props: {
    addStatsData: Object,
    integrations: Array,
    predefinedIntegrations: Array,
  },
  data() {
    return {
      selectedApplicationEvents: [],
      sanitizedEvent: "",
      limitOptions: [10, 20, 50, 100, 150, 200],
    };
  },
  computed: {
    getPredefinedApplications() {
      return (this.integrations || []).filter(
        (e) => e.app_type == "PRE_DEFINED"
      );
    },
  },
  async mounted() {
    this.setSelectedEvents();
    await this.loadRequiredData();
    await this.checkAndLoadDependencies();
  },
  methods: {
    async setAsset() {
      if (this.addStatsData.integrationsSettings.event_id) {
        let sanitizedEvent = this.addStatsData.integrationsSettings.event_id;
        let selectedIntegration = this.selectedIntegrationConnection(
          this.addStatsData.integrationsSettings.integration_id
        );
        let matchedApplication = this.matchedIntegrationApplication(
          selectedIntegration.application
        );
        let events = matchedApplication.events;
        let matchedEvent = events.find((event) => event._id === sanitizedEvent);
        this.addStatsData.integrationsSettings.event = matchedEvent;
        this.addStatsData.apply_date_filter = matchedEvent?.settings
          ?.isDateFilterIncluded
          ? true
          : false;
        await this.loadRequiredData(
          matchedEvent,
          matchedApplication,
          selectedIntegration.connection_id
        );
      }
    },
    setSelectedEvents() {
      if (this.addStatsData?.integrationsSettings.integration_id) {
        let selectedIntegration = this.selectedIntegrationConnection(
          this.addStatsData.integrationsSettings.integration_id
        );
        if (selectedIntegration?.application) {
          let matchedApplication = this.matchedIntegrationApplication(
            selectedIntegration.application
          );
          if (matchedApplication?.events) {
            if (this.addStatsData.type.toLowerCase() === "stat") {
              this.selectedApplicationEvents = matchedApplication.events.filter(
                (event) =>
                  event.settings.supportType.toLowerCase() === "stat" ||
                  event.settings.supportType.toLowerCase() === "table"
              );
            } else {
              this.selectedApplicationEvents = matchedApplication.events.filter(
                (event) => event.settings.supportType.toLowerCase() === "chart"
              );
            }
          } else {
            this.selectedApplicationEvents = [];
          }
        }
      }
    },
    selectedIntegrationConnection(integration_id) {
      return this.getPredefinedApplications.find(
        (e) => e._id == integration_id
      );
    },
    matchedIntegrationApplication(application) {
      return this.predefinedIntegrations.find((e) => e.key === application);
    },
    async loadRequiredData(event, application, connectionId) {
      try {
        if (event?.settings.requiredFields.length) {
          let filterNoDependencyFields = event.settings.requiredFields.filter(
            (field) => field.dependencyField?.length === 0
          );
          await Promise.all(
            filterNoDependencyFields.map(async (ndpField) => {
              let payload = {
                appName: application.key,
                connectionId: connectionId,
                field: ndpField.key,
              };
              let response = await this.getIntegrationRequiredData(payload);
              ndpField.values = response.data;
              return response.data;
            })
          );
        }
      } catch (err) {
        return err;
      }
    },
    async loadDependentFields(field) {
      try {
        let event = this.addStatsData.integrationsSettings.event;
        let dependencies = this.addStatsData.integrationsSettings.dependencies;
        let activeDependencies = [];
        event.settings.requiredFields.forEach((item) => {
          if (
            item.dependencyField &&
            item.dependencyField.includes(field.key)
          ) {
            item.dependencyField.map((value) => {
              let isActiveDependentField = false;
              if (!dependencies[value]) {
                isActiveDependentField = false;
              } else {
                isActiveDependentField = true;
              }
              if (isActiveDependentField) {
                activeDependencies.push(item);
              }
            });
          }
        });
        let selectedIntegration = this.selectedIntegrationConnection(
          this.addStatsData.integrationsSettings.integration_id
        );
        if (activeDependencies.length) {
          await Promise.all(
            activeDependencies.map(async (field) => {
              let payload = {
                appName: selectedIntegration.application,
                connectionId: selectedIntegration.connection_id,
                field: field.key,
                parameters: dependencies,
              };
              let response = await this.getIntegrationRequiredData(payload);
              field.values = response.data;
              return response.data;
            })
          );
        }
        return;
      } catch (err) {
        return err;
      }
    },
    async getIntegrationRequiredData(payload) {
      try {
        let response = await postAPICall(
          "POST",
          "integrations/service/get-user-data",
          payload
        );
        return response;
      } catch (err) {
        return err;
      }
    },
    async checkAndLoadDependencies() {
      let dependencies = this.addStatsData.integrationsSettings.dependencies;
      let event = this.addStatsData.integrationsSettings.event;
      let keys = Object.keys(dependencies);
      await Promise.all(
        keys.map(async (key) => {
          let matchedOne = event.settings.requiredFields.find(
            (field) => field.key === key && !field.dependencyField.length
          );
          if (matchedOne) {
            await this.loadDependentFields(matchedOne);
          }
        })
      );
    },
    resetForm() {
      const defaultValues = {
        event_id: "",
        dependencies: {},
        selectedFields: [],
        groupBy: "",
        event: null,
      };

      Object.keys(defaultValues).forEach((key) => {
        this.$set(
          this.addStatsData.integrationsSettings,
          key,
          defaultValues[key]
        );
      });
    },

    resetFormAndUpdateEvents() {
      this.resetForm();
      this.setSelectedEvents();
    },
  },
};
</script>
